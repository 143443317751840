import request from '@/utils/request'

export function getCustomerList(params) {
    return request({
        url: '/customer/page',
        method: 'GET',
        params,
    })
}

export function createCustomer(data) {
    return request({
        url: data.id ? `/customer/update/${data.id}` : `/customer/create`,
        method: 'POST',
        data,
    })
}

export function deleteCustomer(data) {
    return request({
        url: '/customer/delete',
        method: 'POST',
        data,
    })
}

export function getCustomerDetail(params) {
    return request({
        url: '/customer/getById',
        method: 'GET',
        params,
    })
}





