<template>
  <div class="full-card">
    <div class="handle-area">
      <a-button type="primary" icon="plus" @click="openCreate" v-has-permission="['addProject']">新增项目</a-button>
      <div class="filter margin-l20" @click="filterShow = true"></div>
    </div>
    <a-card :body-style="{'height': '100%', 'overflow-y' : 'auto'}">
      <div class="">
        <div class="cursor" @click="goDetail(item)" v-for="(item, index) in list" :key="item.id">
          <div class="margin-b20">
            <div class="field-header">{{ item.projectName }}</div>
            <div class="padding-10">
              <a-row type="flex">
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">项目名称：</span>
                  <span>{{ item.projectName }}</span>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">项目进度：</span>
                  <div class="width-150">
                    <a-progress :percent="item.progressValue || 0" />
                  </div>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">延误工期（日）：</span>
                  <span class="">{{ item.delayDays }}</span>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">客户名称：</span>
                  <span>{{ item.customerName }}</span>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">创建时间：</span>
                  <span>{{ item.createTime }}</span>
                </a-col>
              </a-row>
              <a-row type="flex">
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">项目负责人：</span>
                  <span>{{ item.projectLeaderName }}</span>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">里程碑进度：</span>
                  <div class="width-150">
                    <a-progress :percent="item.milestoneProgress || 0" />
                  </div>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">启动日期：</span>
                  <span class="">{{ item.startDate }}</span>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">主要干系人：</span>
                  <span>{{ item.stakeholderName }}</span>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">创建人：</span>
                  <span>{{ item.creator }}</span>
                </a-col>
              </a-row>
              <a-row type="flex">
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">项目状态：</span>
                  <span>{{ statusName[item.projectStatus] }}</span>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">任务进度：</span>
                  <div class="width-150">
                    <a-progress :percent="item.taskProgress || 0" />
                  </div>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">计划结束日期：</span>
                  <span class="">{{ item.planEndDate }}</span>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">联系方式：</span>
                  <span>{{ item.phone }}</span>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">最近修改时间：</span>
                  <span>{{ item.updateTime }}</span>
                </a-col>
              </a-row>
              <a-row type="flex">
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">项目等级：</span>
                  <div class="display-f align-items-c">
                    <img src="@/assets/image/common/star_fill.png" alt="" class="margin-r5" v-for="star in item.projectLevel" :key="star">
<!--                    <img src="@/assets/image/common/star.png" alt="">-->
                  </div>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">处理问题：</span>
                  <div class="width-150">
                    <a-progress :percent="item.problemProgress || 0" />
                  </div>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">实际结束日期：</span>
                  <span class="">{{ item.actualEndDate}}</span>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">客户级别：</span>
                  <span v-if="item.customerLevel === 1">重要</span>
                  <span v-if="item.customerLevel === 2">普通</span>
                  <span v-if="item.customerLevel === 3">非优</span>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">修改人：</span>
                  <span>{{ item.modifier }}</span>
                </a-col>
              </a-row>
              <a-row type="flex">
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">项目地址：</span>
                  <span class="flex1">{{ item.province + item.city + item.address }}</span>
                </a-col>
                <a-col flex="1" class="display-f align-items-c padding-tb5">
                  <span class="width-120">预算支出：</span>
                  <div class="width-150">
                    <a-progress :percent="item.planExpenditure || 0" />
                  </div>
                </a-col>
                <a-col flex="1"></a-col>
                <a-col flex="1"></a-col>
                <a-col flex="1"></a-col>
              </a-row>
            </div>
          </div>
        </div>
      </div>
      <div class="display-f justify-end">
        <a-pagination :total="pagination.total" @change="pageChange" show-less-items style="margin-top: 20px"/>
      </div>
    </a-card>
    <a-drawer
        title="过滤器"
        placement="right"
        :visible="filterShow"
        @close="filterShow = false"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="filterForm"
            class="drawer-form"
        >
          <a-form-model-item :wrapperCol="{ span: 24 }">
            <a-input-search placeholder="项目名称/项目负责人/客户名称" v-model="filterForm.queryParam.name" @search="onFilter" />
          </a-form-model-item>
          <a-row style="margin-top: 20px">
            <a-form-model-item  label="项目状态">
              <a-select v-model="filterForm.queryParam.projectStatus" placeholder="请选择项目状态">
                <a-select-option :value="1">洽谈中</a-select-option>
                <a-select-option :value="2">规划中</a-select-option>
                <a-select-option :value="3">实施中</a-select-option>
                <a-select-option :value="4">验收中</a-select-option>
                <a-select-option :value="5">已完成</a-select-option>
                <a-select-option :value="6">已取消</a-select-option>
                <a-select-option :value="7">已搁置</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="项目等级">
              <a-select v-model="filterForm.queryParam.projectLevel" placeholder="请选择项目等级">
                <a-select-option :value="1">一星</a-select-option>
                <a-select-option :value="2">二星</a-select-option>
                <a-select-option :value="3">三星</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="项目地区">
              <District :level="2" @change="filterDistrict"  v-if="resetShow"></District>
            </a-form-model-item>
          </a-row>
          <a-row style="margin-top: 20px">
            <a-form-model-item label="项目进度">
              <a-checkbox-group
                  v-model="filterForm.queryParam.projectProgressList"
                  name="checkboxgroup"
                  :options="checkOptions"
              />
            </a-form-model-item>
            <a-form-model-item label="预算支出">
              <a-checkbox-group
                  v-model="filterForm.queryParam.budgetExpenditureList"
                  name="levelGroup"
                  :options="budgetOptions"
              />
            </a-form-model-item>
            <a-form-model-item label="启动日期">
              <a-range-picker
                  :placeholder="['请选择开始日期', '请选择结束日期']"
                  style="width: 100%;"
                  @change="(date, dateStr) => rangeChange(date, dateStr, 'start')"
                  v-if="resetShow"
              />
            </a-form-model-item>
            <a-form-model-item label="计划结束日期">
              <a-range-picker
                  :placeholder="['请选择开始日期', '请选择结束日期']"
                  style="width: 100%;"
                  @change="(date, dateStr) => rangeChange(date, dateStr, 'plan')"
                  v-if="resetShow"
              />
            </a-form-model-item>
            <a-form-model-item label="实际结束日期">
              <a-range-picker
                  :placeholder="['请选择开始日期', '请选择结束日期']"
                  style="width: 100%;"
                  @change="(date, dateStr) => rangeChange(date, dateStr, 'actual')"
                  v-if="resetShow"
              />
            </a-form-model-item>
            <a-form-model-item  label="客户名称">
              <a-select v-model="filterForm.queryParam.customerId" placeholder="请选择客户名称">
                <a-select-option :value="item.id" v-for="(item, index) in customerList" :key="item.id">{{ item.customerName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item  label="项目负责人">
              <a-select v-model="filterForm.queryParam.projectLeaderId" placeholder="请选择项目负责人">
                <a-select-option :value="item.id" v-for="(item, index) in principalList" :key="item.id">{{item.staffName }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="search" @click="onFilter">筛选</a-button>
          <a-button type="default" icon="reload" class="margin-l15" @click="onReset">重置</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        title="新增项目"
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-form-model-item label="项目名称" prop="projectName">
            <a-input placeholder="请输入项目名称" v-model="form.projectName"/>
          </a-form-model-item>
          <a-row>
            <a-form-model-item  label="项目状态" prop="projectStatus">
              <a-select v-model="form.projectStatus" placeholder="请选择项目状态">
                <a-select-option :value="1">洽谈中</a-select-option>
                <a-select-option :value="2">规划中</a-select-option>
                <a-select-option :value="3">实施中</a-select-option>
                <a-select-option :value="4">验收中</a-select-option>
                <a-select-option :value="5">已完成</a-select-option>
                <a-select-option :value="6">已取消</a-select-option>
                <a-select-option :value="7">已搁置</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="项目等级" prop="projectLevel">
              <a-select v-model="form.projectLevel" placeholder="请选择项目等级">
                <a-select-option :value="1">一星</a-select-option>
                <a-select-option :value="2">二星</a-select-option>
                <a-select-option :value="3">三星</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="项目地区" prop="privince">
              <District :level="2" @change="getDistrict" v-model="form.privince"></District>
            </a-form-model-item>
            <a-form-model-item label="项目经纬度" prop="longitude">
              <a-row :gutter="10">
                <a-col span="12">
                  <a-input placeholder="请输入项目经度" v-model="form.longitude"/>
                </a-col>
                <a-col span="12">
                  <a-input placeholder="请输入项目纬度" v-model="form.latitude"/>
                </a-col>
              </a-row>
            </a-form-model-item>
            <a-form-model-item label="详细地址" prop="address">
              <a-textarea placeholder="请输入详细地址" v-model="form.address" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
          </a-row>
          <a-row style="margin-top: 20px">
            <a-form-model-item label="启动日期">
              <a-date-picker
                  @change="startChange"
                  style="width: 100%"
              />
            </a-form-model-item>
            <a-form-model-item label="计划结束日期">
              <a-date-picker
                  @change="endChange"
                  style="width: 100%"
              />
            </a-form-model-item>
            <a-form-model-item  label="客户名称">
              <a-select v-model="form.customerId" placeholder="请选择客户">
                <a-select-option :value="item.id" v-for="(item, index) in customerList" :key="item.id">{{ item.customerName }}</a-select-option>
              </a-select>
            </a-form-model-item>
<!--            <a-form-model-item  label="项目负责人">-->
<!--              <a-select v-model="form.projectLeaderId" placeholder="请选择项目负责人">-->
<!--                <a-select-option :value="item.id" v-for="(item, index) in principalList" :key="item.id">{{item.staffName }}</a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-model-item>-->
            <a-form-model-item  label="备注">
              <a-textarea placeholder="请输入备注" v-model="form.remark" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button type="default" icon="close" class="margin-l15" @click="closeCreate">取消</a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import District from '@/components/district/district'
import { createProject, getProjectList } from '../../api/project/project'
import { getCustomerList } from '../../api/customer/customer'
import { getStaffList } from '../../api/humanResources/staff'
import { mapState } from 'vuex'
export default {
  name: 'projectList',
  components: {
    District
  },
  data () {
    return {
      filterShow: false,
      createShow: false,
      filterForm: {
        queryParam: {},
        pageNum: 1,
        pageSize: 10
      },
      form: {},
      list: [],
      statusName: ['', '洽谈中', '规划中', '实施中', '验收中', '已完成', '已取消', '已搁置'],
      checkOptions: [
        { label: '计划内', value: 1 },
        { label: '滞后', value: 2 },
        { label: '提前', value: 3 }
      ],
      budgetOptions: [
        { label: '计划内', value: 1 },
        { label: '超额', value: 2 }
      ],
      customerList: [],
      principalList: [],
      rules: {
        projectName: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ],
        projectStatus: [
          { required: true, message: '请选择项目状态', trigger: 'change' }
        ],
        projectLevel: [
          { required: true, message: '请选择项目等级', trigger: 'change' }
        ],
        privince: [
          { required: true, message: '请选择项目地区', trigger: 'change' }
        ],
        longitude: [
          { required: true, message: '请输入经纬度', trigger: 'blur' }
        ]
      },
      page: 1,
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      resetShow: true
    }
  },
  mounted () {
    if (this.$route.query.type) {
      this.filterForm.queryParam.projectStatus = parseInt(this.$route.query.type)
    }
    this.getList()
    this.getCustomer()
    this.getPrincipal()
  },
  methods: {
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getProjectList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createProject(this.form).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    onFilter () {
      this.filterForm.pageNum = 1
      this.getList().then(() => {
        this.filterShow = false
      })
    },
    onReset () {
      this.filterForm = {
        queryParam: {},
        pageNum: 1,
        pageSize: 10
      }
      this.resetShow = false
      setTimeout(() => {
        this.resetShow = true
      }, 100)
      this.getList()
    },
    rangeChange (date, dateStr, type, form) {
      switch (type) {
        case 'start' :
          this.filterForm.queryParam.beginStartDate = dateStr[0]
          this.filterForm.queryParam.endStartDate = dateStr[1]
          break
        case 'plan' :
          this.filterForm.queryParam.beginPlanEndDate = dateStr[0]
          this.filterForm.queryParam.endPlanEndDate = dateStr[1]
          break
        case 'actual' :
          this.filterForm.queryParam.beginActualEndDate = dateStr[0]
          this.filterForm.queryParam.endActualEndDate = dateStr[1]
          break
      }
    },
    startChange (date, dateStr) {
      this.form.startDate = dateStr
    },
    endChange (date, dateStr) {
      this.form.planEndDate = dateStr
    },
    getCustomer () {
      getCustomerList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.customerList = res.data.list
      })
    },
    getPrincipal () {
      getStaffList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.principalList = res.data.list
      })
    },
    getDistrict (data) {
      this.form.province = data[0]
      this.form.city = data[1]
    },
    filterDistrict (data) {
      this.filterForm.queryParam.province = data[0]
      this.filterForm.queryParam.city = data[1]
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages
      this.getList()
    },
    openCreate () {
      this.createShow = true
    },
    closeCreate () {
      this.form = {}
      this.createShow = false
    },
    goDetail (item) {
      this.$util.goHidden(this.authority.viewProjectDetail, `/project/projectDetail?id=${item.id}`, item.projectName)
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped lang="scss">


</style>
