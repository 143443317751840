import request from '@/utils/request'

export function getStaffList(params) {
    return request({
        url: '/staff/page',
        method: 'GET',
        params,
    })
}

export function createStaff(data) {
    return request({
        url: data.id ? `/staff/update/${data.id}` : `/staff/create`,
        method: 'POST',
        data,
    })
}

export function deleteStaff(data) {
    return request({
        url: '/staff/delete',
        method: 'POST',
        data,
    })
}

export function getEmployeeDetail(params) {
    return request({
        url: '/staff/getById',
        method: 'GET',
        params,
    })
}

export function saveStaffEducation(data) {
    return request({
        url: '/staff/saveStaffEducation',
        method: 'POST',
        data,
    })
}

export function saveStaffExperience(data) {
    return request({
        url: '/staff/saveStaffWorkExperience',
        method: 'POST',
        data,
    })
}




