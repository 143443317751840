<template>
  <a-cascader
      v-model="val"
      :options="options"
      :fieldNames="fieldNames"
      placeholder="请选择省市区"
      @change="onChange"
      :disabled="disabled"
  />
</template>

<script>
import districtList from '@/assets/lib/district/district.json'
export default {
  name: 'district',
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: Array,
      default: null
    },
    level: {
      type: Number,
      default: 3
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fieldNames: {
        label: 'name',
        value: 'name',
        children: 'districts'
      },
      options: [],
      levelVal: {
        'province': 1,
        'city': 2,
        'district': 3,
      },
      val: []
    }
  },
  created () {

  },
  mounted () {
    if (this.level === 3) {
      this.options = districtList
    } else {
      this.onFilter(districtList)
      this.options = districtList
    }
    this.val = this.value
  },
  methods: {
    onChange(value, options) {
      // this.$emit('change', {value, options})
      this.$emit('change', this.val)
    },
    onFilter (data) {
      data.forEach(item => {
        if (this.levelVal[item.level] === this.level) {
          delete item.districts
        } else {
          if (item.districts && item.districts.length) this.onFilter(item.districts)
        }
      })
    }
  },
  watch: {
    value (n, o) {
      this.val = n
    }
  }
}
</script>

<style scoped>

</style>
