import request from '@/utils/request'

export function getProjectList(data) {
    return request({
        url: `/projectInfo/page?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
        method: 'POST',
        data: data.queryParam,
    })
}

export function createProject (data) {
    return request({
        url: data.id ? `/projectInfo/update/${data.id}` : `/projectInfo/create`,
        method: 'POST',
        data
    })
}

export function deleteProject(data) {
    return request({
        url: '/projectInfo/delete',
        method: 'POST',
        data,
    })
}

export function getProjectPrincipal(params) {
    return request({
        url: '/projectInfo/getProjectLeaders',
        method: 'GET',
        params,
    })
}

export function getProjectDetail(params) {
    return request({
        url: '/projectInfo/getById',
        method: 'GET',
        params,
    })
}








